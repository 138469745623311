import React from 'react';
import { CartProvider } from './src/context/cart'; // Adjust the import path as needed
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./src/aws-exports";
import CookieConsent from "react-cookie-consent";
import './src/pages/index.scss';
Amplify.configure(awsExports);
Auth.configure(awsExports);

const cookieStyles = {

}

const cookieButtonStyles: React.CSSProperties = {
    backgroundColor: '#fff',
    color: '#000',
    fontWeight: '700',
    marginTop: '0.25rem',
    padding: '0.75rem 1.5rem',
    maxHeight: '30vh'
    // borderRadius: '1rem',
    // marginLeft: '-1px',
}

// Define the wrapRootElement function
export const wrapRootElement = ({ element }) => {
    return <CartProvider>
        {element}
        <CookieConsent
            location="bottom"
            buttonText="Zgadzam się"
            cookieName="myAwesomeCookieName2"
            containerClasses='cookie-overlay'
            buttonWrapperClasses='cookie-button-wrapper'
            buttonClasses='cookie-button'
            buttonStyle={cookieButtonStyles}
            expires={150}
        >
            Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie
        </CookieConsent>
    </CartProvider>;
};
